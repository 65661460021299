import { graphql, useStaticQuery } from "gatsby";

import Category from "../components/Global/Category";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";
import ShareLinks from "../components/Global/ShareLinks";
import SmartText from "../utils/TextHandler";

// ====

const News_Post = ({ blok, pageContext }) => {
    let doc;
    let parent_id;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
        parent_id = pageContext.story.parent_id;
    } else {
        doc = blok;
        parent_id = 0;
    }

    const newsParent = useStaticQuery(graphql`
        {
            allStoryblokEntry(
                filter: {
                    field_component: { in: ["news", "game"] }
                    is_startpage: { eq: true }
                }
            ) {
                nodes {
                    field_title_string
                    field_label_string
                    field_colour_string
                    parent_id
                    full_slug
                }
            }
        }
    `);

    let parents;
    if (newsParent) {
        parents = newsParent.allStoryblokEntry.nodes;
    } else {
        parents = blok;
    }

    let parent;
    for (let i = 0; i < parents.length; i++) {
        if (parents[i].parent_id === parent_id) {
            parent = parents[i];
        }
    }

    const openGraph = {
        type: "website",
        title: doc.preview_title || doc.title,
        description: doc.preview_body || doc.body,
        image:
            (doc.meta_image?.filename && doc.meta_image) ||
            (doc.preview_image?.filename && doc.preview_image) ||
            (doc.image?.filename && doc.image),
    };

    return (
        <Layout
            colour={
                parent?.field_colour_string
                    ? parent.field_colour_string
                    : "black"
            }
            image={doc.image && doc.image}
            size="small"
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <section className="mb-4 mb-md-5">
                <div className="container">
                    <div className="brand-background-white p-4">
                        {doc.title && (
                            <h1 className="brand-font-uppercase brand-font-bold">
                                {doc.title}
                            </h1>
                        )}
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="mb-4 mb-md-0">
                                <p className="brand-font-uppercase brand-font-bold brand-text-grey small">
                                    Posted on{" "}
                                    <Moment
                                        format="Do MMMM, YYYY"
                                        date={doc.date}
                                    />
                                    {doc.author
                                        ? ` by ${doc.author.content.title}`
                                        : ""}
                                </p>
                                {parent_id ? (
                                    <p>
                                        <Category id={parent_id} link />
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <ShareLinks
                                meta={doc.meta}
                                openGraph={openGraph}
                                facebook
                                twitter
                                linkedin
                                email
                            />
                        </div>
                        {doc.body && <SmartText>{doc.body}</SmartText>}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default News_Post;

News_Post.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

News_Post.defaultProps = {
    pageContext: null,
    blok: null,
};
