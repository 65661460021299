import React from "react";
import PropTypes from "prop-types";
import Layout from "../Templates/Layout";
import Meta from "../../utils/Meta";
import SmartText from "../../utils/TextHandler";
import Image from "../Global/Image";
import Link from "../Global/Link";
import Category from "../Global/Category";
import Pagination from "../Global/Pagination";

// ====

const sbNews = ({ blok }) => {
  let doc = blok;

  let items = {
    nodes: [
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article one", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article two", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article three", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article four", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article five", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
      {
        full_slug: "news/gaming/featured-news",
        field_date_string: "2021-09-16 00:00",
        content:
          '{"title":"Article six", "preview":"Super important news here", "image":null}',
        parent_id: null,
      },
    ],
  };

  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section>
        <div className="container">
          <div className="alert alert-info" role="alert">
            This is a mock <i>news</i> page in the Storyblok folder. Edit it at{" "}
            <code>src/components/Storyblok/sbNews.js</code>
          </div>
          {doc.title && <h1 className="brand-font">{doc.title}</h1>}
          {doc.body && <SmartText>{doc.body}</SmartText>}
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3">
            {items &&
              items.nodes.map((node) => {
                const item = JSON.parse(node.content);
                return (
                  <div className="col">
                    <div className="card h-100">
                      <Image
                        src={item.image && item.image.filename}
                        sm="570x0"
                        md="390x0"
                        lg="470x0"
                        alt={item.title & item.title}
                      />

                      <div className="card-body">
                        <p>
                          {item.featured && (
                            <span className="badge rounded-pill bg-secondary text-light me-2">
                              Featured news
                            </span>
                          )}
                          {node.parent_id && (
                            <span className="badge rounded-pill bg-primary text-light me-2">
                              <Category id={node.parent_id} />
                            </span>
                          )}
                        </p>

                        {item.title && (
                          <h5 className="card-title">{item.title}</h5>
                        )}

                        {item.preview && (
                          <SmartText method="text">{item.preview}</SmartText>
                        )}
                      </div>

                      {node.full_slug && (
                        <div className="card-footer">
                          <Link to={`/${node.full_slug}`} icon>
                            Link
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <Pagination
        numPages={4}
        currentPage={1}
        paginationPrefix="page"
        homepage="news"
      />

      <section>
        <div className="container py-5 small">
          <h3>Debug: data pulled from Storyblock:</h3>
          <code>{JSON.stringify(doc)}</code>
        </div>
      </section>
    </Layout>
  );
};

export default sbNews;

sbNews.propTypes = {
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

sbNews.defaultProps = {
  blok: null,
};
