import Image from "../Global/Image";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const TimelineCard = (props) => {
    const { item } = props;

    return (
        <div className="w-100 brand-background-white brand-text-black position-relative d-flex flex-column">
            <Image
                src={item.image?.filename}
                sm="470x264"
                md="600x338"
                lg="600x338"
                alt={item.title}
                className="w-100"
            />
            <div className="p-3 p-lg-4">
                {item.title && (
                    <h4 className="brand-font-uppercase brand-font-bold">
                        {item.title}
                    </h4>
                )}

                {item.body && (
                    <SmartText method="text" className="mb-4">
                        {item.body}
                    </SmartText>
                )}
                {item.date && (
                    <p className="brand-font-uppercase brand-font-bold brand-text-grey">
                        <Moment format="Do MMMM YYYY" date={item.date} />
                    </p>
                )}
            </div>
        </div>
    );
};

export default TimelineCard;

TimelineCard.propTypes = {
    item: PropTypes.object,
};

TimelineCard.defaultProps = {
    item: null,
};
