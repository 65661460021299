import PropTypes from "prop-types";
import React from "react";
import TimelineCard from "../Cards/TimelineCard";

const SectionTimeline = (props) => {
    const { doc } = props;

    function oddEven(x) {
        return x & 1 ? "odd" : "even";
    }

    return (
        <section className="mb-4 mb-md-5">
            <div className="container position-relative py-5">
                <div className="position-absolute top-0 start-50 h-100 p-1 brand-background-blue rounded translate-middle-x" />
                <div className="position-absolute top-0 start-50 p-3 brand-background-blue rounded rounded-circle translate-middle" />
                <div className="position-absolute bottom-0 start-50 p-3 brand-background-blue rounded rounded-circle translate-middle-x" />
                {doc.map((node, index) => {
                    return (
                        <div
                            key={node._uid}
                            className={`row position-relative mb-4 mb-md-0 ${
                                oddEven(index) === "odd"
                                    ? "flex-md-row-reverse"
                                    : ""
                            }`}
                        >
                            <div className="position-absolute top-50 start-50 w-auto p-3 brand-background-blue rounded rounded-circle translate-middle" />

                            <div className="col-10 mx-auto col-md-5 mx-md-0">
                                <TimelineCard item={node} />
                            </div>
                            <div className="d-none d-md-block col-md-1 position-relative">
                                <div className="position-absolute top-50 start-0 w-100 p-1 brand-background-blue rounded translate-middle-y" />
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default SectionTimeline;

SectionTimeline.propTypes = {
    doc: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SectionTimeline.defaultProps = {
    doc: null,
};
