import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import PropTypes from "prop-types";
import React from "react";
import Sections from "../components/Sections/Sections";

// ====

const Default = (props) => {
    const { pageContext, blok } = props;
    let doc;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image: (doc.meta_image?.filename && doc.meta_image) || doc.image,
    };
    //  console.log("BUILD TRIGGER TEST");

    return (
        <Layout
            title={doc.title && doc.title}
            colour={doc.colour && doc.colour}
            image={doc.image && doc.image}
            video={doc.video && doc.video}
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <Sections
                items={doc.sections && doc.sections}
                date={doc.date && doc.date}
            />
        </Layout>
    );
};

export default Default;

Default.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

Default.defaultProps = {
    pageContext: null,
    blok: null,
};
