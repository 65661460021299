import Image from "../Global/Image";
import Link from "../Global/Link";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const LocationCard = (props) => {
    const { item } = props;

    return (
        <div className="h-100 w-100 brand-background-white position-relative brand-text-black d-flex flex-column">
            <div className="ratio ratio-16x9 brand-background-grey">
                <Image
                    src={item.image?.filename}
                    sm="470x264"
                    md="470x264"
                    lg="470x264"
                    alt={item.title}
                    className="w-100"
                />
            </div>
            <div className="p-4 pb-0">
                {item.title && (
                    <h4 className="brand-font-uppercase brand-font-bold">
                        {item.title}
                    </h4>
                )}
                {item.address && (
                    <SmartText method="text">{item.address}</SmartText>
                )}
            </div>
            {item.link && (
                <div className="p-4 text-end mt-auto">
                    <Link to={item.link} icon stretched />
                </div>
            )}
        </div>
    );
};

export default LocationCard;

LocationCard.propTypes = {
    item: PropTypes.object,
};

LocationCard.defaultProps = {
    item: null,
};
