import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";

const SectionContactForm = () => {
    const pageUrl =
        typeof window !== "undefined" ? window.location.href : "No URL";

    const [departmentError, setDepartmentError] = React.useState(false);

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        const {
            message,
            formLocation,
            email,
            department,
            name,
            postcode,
            phone,
        } = e.target.elements;

        if (
            !department.value ||
            department.value.toLowerCase() === "select department"
        ) {
            setDepartmentError(true);
            return;
        }

        await fetch("/.netlify/functions/sendContactFormEmail", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_message: message.value,
                user_email: email.value,
                department: department.value,
                user_name: name.value,
                form_location: formLocation.value,
                user_postcode: postcode.value,
                user_phone: phone.value,
            }),
        });
        navigate("/thanks/");
    };

    return (
        <section className="mb-4 mb-md-5">
            <div className="container">
                <div className="brand-background-white p-4">
                    <h2 className="brand-font-uppercase brand-font-bold mb-4">
                        Contact us
                    </h2>
                    <>
                        <form name="contact" onSubmit={formSubmitHandler}>
                            <div className="row mb-4">
                                <div className="col-12 col-md-6 position-relative">
                                    <label
                                        className="brand-form-control-OT-check"
                                        for="postcode"
                                    ></label>
                                    <input
                                        className="brand-form-control-OT-check"
                                        autoComplete="do-not-autofill"
                                        type="text"
                                        name="postcode"
                                        id="postcode"
                                        placeholder="Enter your postcode"
                                    />
                                    <label
                                        className="brand-form-control-OT-check"
                                        for="phone"
                                    ></label>
                                    <input
                                        className="brand-form-control-OT-check"
                                        autoComplete="do-not-autofill"
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Enter your phone"
                                    />
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="contact"
                                    />
                                    <input
                                        type="hidden"
                                        id="formLocation"
                                        value={
                                            pageUrl
                                                ? pageUrl
                                                : "Location unknown"
                                        }
                                    />
                                    <select
                                        name="department"
                                        component="select"
                                        className={`form-select brand-form-select`}
                                        aria-label="Select department"
                                        onChange={() =>
                                            setDepartmentError(false)
                                        }
                                        required
                                    >
                                        <option defaultValue hidden>
                                            Select department
                                        </option>
                                        <option value="business">
                                            Business Enquiries
                                        </option>
                                        <option value="press">
                                            Press Enquiries
                                        </option>
                                        <option value="business">
                                            Performance Marketing / UA Enquiries
                                        </option>
                                        <option value="monetisation">
                                            Monetisation Enquiries
                                        </option>
                                    </select>
                                    {departmentError && (
                                        <p className="text-danger">
                                            Please select a department
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="row gy-4 mb-4">
                                <div className="col-12 col-md-6 position-relative">
                                    <input
                                        type="text"
                                        name="name"
                                        className={`form-control brand-form-control`}
                                        placeholder="Your name"
                                        aria-label="Your name"
                                        required
                                    />
                                </div>
                                <div className="col-12 col-md-6 position-relative">
                                    <input
                                        type="email"
                                        name="email"
                                        className={`form-control brand-form-control`}
                                        placeholder="Your email"
                                        aria-label="Your email"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12 position-relative">
                                    <textarea
                                        component="textarea"
                                        name="message"
                                        className={`form-control brand-form-control`}
                                        placeholder="Leave a message"
                                        aria-label="Leave a message"
                                        rows="3"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row text-end">
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn brand-btn"
                                    >
                                        Send
                                    </button>
                                    {/* <Link button="real" icon="paper-plane">
                                        Send
                                    </Link> */}
                                </div>
                            </div>
                        </form>
                    </>
                </div>
            </div>
        </section>
    );
};

export default SectionContactForm;

SectionContactForm.propTypes = {
    doc: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SectionContactForm.defaultProps = {
    doc: null,
};
