import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import React from "react";
import Sections from "../components/Sections/Sections";
import { graphql } from "gatsby";

// Markup
const IndexPage = (props) => {
    const { data, blok } = props;

    let doc;
    if (data) {
        doc = JSON.parse(data.index.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
    };

    return (
        <Layout
            title={doc.title && doc.title}
            image={doc.image && doc.image}
            video={doc.video && doc.video}
            video_modal={doc.video_modal?.vimeo_raw && doc.video_modal}
            // video_modal={doc.video?.vimeo_raw && doc.video}
        >
            <Meta openGraph={openGraph} meta={doc.meta} location="index" />
            <Sections items={doc.sections && doc.sections} />
        </Layout>
    );
};

export default IndexPage;

export const QUERY = graphql`
    query IndexPageQuery {
        index: storyblokEntry(slug: { eq: "home" }) {
            content
        }
    }
`;
