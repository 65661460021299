import Layout from "../components/Templates/Layout";
import Link from "../components/Global/Link";
import Meta from "../utils/Meta";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";
import ShareLinks from "../components/Global/ShareLinks";
import SmartText from "../utils/TextHandler";
import { graphql } from "gatsby";

// ====

const PatchNote = ({ blok, data, pageContext }) => {
    //let { parent_slug } = pageContext || null;
    let doc;
    let doc_parent;
    if (pageContext && data) {
        doc = JSON.parse(pageContext.story.content);
        doc_parent = JSON.parse(data.index.content);
    } else {
        doc = blok;
        doc_parent = null;
    }

    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image:
            (doc.meta_image?.filename && doc.meta_image) ||
            (doc.image?.filename && doc.image)
    };

    return (
        <Layout
            colour="red"
            image={doc_parent?.hero && doc_parent?.hero}
            size="small"
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <section className="mb-4 mb-md-5">
                <div className="container">
                    <div className="brand-background-white p-4">
                        {doc.title && (
                            <h1 className="brand-font-uppercase brand-font-bold">
                                {doc.title}
                            </h1>
                        )}

                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="mb-4 mb-md-0">
                                <p className="brand-font-uppercase brand-font-bold brand-text-grey small">
                                    Posted on{" "}
                                    <Moment
                                        format="Do MMMM, YYYY"
                                        date={doc.date}
                                    />
                                </p>
                                {doc.version ? (
                                    <p>
                                        <span className="badge brand-font-bold text-decoration-none brand-badge brand-background-red">
                                            {doc.version}
                                        </span>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <ShareLinks
                                meta={doc.meta}
                                openGraph={openGraph}
                                facebook
                                twitter
                                linkedin
                                email
                            />
                        </div>
                        {doc.body && <SmartText>{doc.body}</SmartText>}
                        {doc_parent &&
                            (doc_parent.feedback ||
                                doc_parent.issues ||
                                doc_parent.support) && (
                                <>
                                    <hr />
                                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                                        {doc_parent.feedback && (
                                            <div className="col-auto mb-4 mb-md-0 mx-0 mx-md-2">
                                                <Link
                                                    to={doc_parent.feedback}
                                                    button
                                                    icon="relative"
                                                >
                                                    Feedback
                                                </Link>
                                            </div>
                                        )}
                                        {doc_parent.issues && (
                                            <div className="col-auto mb-4 mb-md-0 mx-0 mx-md-2">
                                                <Link
                                                    to={doc_parent.issues}
                                                    button
                                                    icon="relative"
                                                >
                                                    Issues
                                                </Link>
                                            </div>
                                        )}
                                        {doc_parent.support && (
                                            <div className="col-auto mb-4 mb-md-0 mx-0 mx-md-2">
                                                <Link
                                                    to={doc_parent.support}
                                                    button
                                                    icon="relative"
                                                >
                                                    Support
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default PatchNote;

export const QUERY = graphql`
    query PatchArticleDefaultQuery($parent_slug: String!) {
        # Query the data from the index page
        index: storyblokEntry(
            field_component: { eq: "game" }
            lang: { eq: "default" }
            slug: { eq: $parent_slug }
        ) {
            content
        }
    }
`;

PatchNote.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

PatchNote.defaultProps = {
    pageContext: null,
    blok: null,
};
