import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import PropTypes from "prop-types";
import React from "react";
import SectionContactForm from "../components/Sections/SectionContactForm";
import SectionLocations from "../components/Sections/SectionLocations";
import SectionSupport from "../components/Sections/SectionSupport";
import Sections from "../components/Sections/Sections";

// ====

const Contact = (props) => {
    const { pageContext, blok } = props;
    let doc;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image: (doc.meta_image?.filename && doc.meta_image) || doc.image,
    };

    return (
        <Layout
            title={doc.title && doc.title}
            colour="black"
            image={doc.image && doc.image}
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <SectionSupport doc={doc.support} />
            <SectionContactForm doc={doc} />
            <SectionLocations doc={doc.locations} />
            <Sections items={doc.sections && doc.sections} />
        </Layout>
    );
};

export default Contact;

Contact.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

Contact.defaultProps = {
    pageContext: null,
    blok: null,
};
