import sbSettings from "./sbSettings";
import sb404 from "./sb404";
import sbDefault from "../../templates/default";
import sbOurstory from "../../templates/ourstory";
import sbContact from "../../templates/contact";
import sbIndexPage from "../../pages/index";
import sbGames from "../../templates/games";
import sbGame from "../../templates/game";
import sbNews from "./sbNews";
import sbNewsPost from "../../templates/news_post";
import sbPatchNote from "../../templates/patch_note";

const ComponentList = {
  settings: sbSettings,
  default: sbDefault,
  ourstory: sbOurstory,
  contact: sbContact,
  index: sbIndexPage,
  games: sbGames,
  game: sbGame,
  news: sbNews,
  news_post: sbNewsPost,
  patch_note: sbPatchNote,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return sb404;
  }
  return ComponentList[type];
};

export default Components;
