import React, { useState } from "react";

import Layout from "../components/Templates/Layout";
import Link from "../components/Global/Link";
import Meta from "../utils/Meta";
import PropTypes from "prop-types";
import SectionGames from "../components/Sections/SectionGames";

// ====

const Games = (props) => {
    const { pageContext, blok } = props;
    let doc;
    if (pageContext) {
        doc = JSON.parse(pageContext.story.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
        image: (doc.meta_image?.filename && doc.meta_image) || doc.image,
    };
    const [showMore, setShowMore] = useState(false);

    return (
        <Layout
            title={doc.title && doc.title}
            colour="red"
            image={doc.image && doc.image}
        >
            <Meta openGraph={openGraph} meta={doc.meta} context={pageContext} />
            <SectionGames doc={doc.games && doc.games} layout="normal" />
            {showMore ? (
                <SectionGames
                    doc={doc.archive && doc.archive}
                    layout="simple"
                />
            ) : (
                <div className="container text-center mb-5">
                    <Link
                        button
                        icon="chevron-down"
                        onClick={() => setShowMore(true)}
                    >
                        See more
                    </Link>
                </div>
            )}
        </Layout>
    );
};

export default Games;

Games.propTypes = {
    pageContext: PropTypes.object,
    blok: PropTypes.object,
};

Games.defaultProps = {
    pageContext: null,
    blok: null,
};
