import React from "react";
import PropTypes from "prop-types";
import Header from "../Templates/Header";
import Footer from "../Templates/Footer";
import CookiesNotice from "../Global/CookiesNotice";
import Image from "../Global/Image";
import SmartText from "../../utils/TextHandler";

const sbSettings = (props) => {
  const { blok } = props;

  if (!blok) {
    return <p>This is not a page</p>;
  }

  let doc = blok;

  return (
    <>
      <section className="py-3 py-md-5 bg-light">
        <div className="container">
          <div className="row mb-5">
            <h2>Global components and settings</h2>
          </div>
          <div className="row mb-5">
            <div className="small fw-bold text-secondary">Main navigation</div>
            <div className="border p-3 bg-white">
              <Header settings={doc} />
            </div>
          </div>
          <div className="row mb-5">
            <div className="small fw-bold text-secondary">Footer</div>
            <div className="border p-3 bg-white">
              <Footer settings={doc} />
            </div>
          </div>
          <div className="row mb-5">
            <div className="small fw-bold text-secondary">Cookie notice</div>
            <div className="border p-3 bg-white">
              <CookiesNotice message={doc.cookies} mode="preview" />
            </div>
          </div>
          <div className="row mb-5">
            <div className="small fw-bold text-secondary">
              Default meta data
            </div>
            <div className="border p-3 bg-white">
              <div className="col-12 col-md-6 col-lg-5">
                <div className="card">
                  <Image
                    src={doc.image && doc.image.filename}
                    sm="570x0"
                    md="390x0"
                    lg="470x0"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      {doc.name && doc.name}
                    </h5>
                    <SmartText className="card-text">
                      {doc.description && doc.description}
                    </SmartText>
                  </div>
                  <div className="card-footer small text-secondary">
                    {doc.domain && doc.domain}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default sbSettings;

sbSettings.propTypes = {
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

sbSettings.defaultProps = {
  blok: null,
};
