import LocationCard from "../Cards/LocationCard";
import PropTypes from "prop-types";
import React from "react";

const SectionLocations = (props) => {
    const { doc } = props;

    return (
        <section className="mb-4 mb-md-5">
            <div className="container">
                <div className="row gy-4 justify-content-center">
                    {doc.map((node, index) => {
                        return (
                            <div key={node._uid} className="col-12 col-md-4">
                                <LocationCard item={node} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionLocations;

SectionLocations.propTypes = {
    doc: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SectionLocations.defaultProps = {
    doc: null,
};
